import React from 'react'
import Footer from '../components/footer/footer'
import Container from '@material-ui/core/Container'
import { useDispatch, useSelector } from 'react-redux'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useStyles from '../utils/styles'
import Layout from '../components/Layout'


class About extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLogin: true,
      email: '',
    }
  }

  componentDidMount() {
    const { dispatch, userData, basicData } = this.props
  }

  onSignup = () => {}

  onSignin = () => {}

  handleChange = event => {
    this.setState({ email: event.target.value })
  }

  render() {
    const { userData, classes } = this.props
    return (
      <Layout  menuIndex={this.props.menuIndex} loader={this.state.showLoader}>
        <Container maxWidth="md" className={classes.rootContainer}>
          <Paper elevation={0}>
            <div style={{ marginTop: 40 }}>
            <div className={classes.aboutTermScrollRoot}>
                <Typography
                  variant="body1"
                  align="center" 
                  gutterBottom
                  style={{ marginBottom: 30, maxHeight: '50vh', textAlign: 'justify', textJustify: 'inter-word' }}
                  component="div"               
                >
                  <Typography variant="h5" component="div">
                    <strong>About Us</strong>
                  </Typography>
                  <Typography variant="body1" component="div" style={{ marginBottom: '16px'}}>
                    Last updated June 18, 2024   
                  </Typography>        
                  <Typography variant="body1" component="div">
                    Veritec Inc. is a pioneer in two-dimensional (2D) matrix coding technology and holds patents for the innovative VeriCode® and VSCode®. These advanced codes, known for their high data density, are both machine-readable and writeable, making them ideal for secure, portable data storage.
                  </Typography>
                  <Typography variant="body1" component="div">
                    Founded in 1982, Veritec has grown into a prominent corporation with three key branches. Our proprietary ecosystem of products ensures that every client receives a custom-built solution tailored to their unique needs.
                  </Typography>
                  <Typography variant="body1" component="div"  style={{ marginBottom: '16px'}}>
                    Security is at the heart of our business philosophy. Coupled with creative innovation, we have successfully addressed some of the most challenging and high-risk identification and payment scenarios. From contributing to NASA space shuttle projects and U.S. Air Force crafts to processing payments for high-risk merchants, Veritec is a trusted partner dedicated to solving problems and delivering results.
                  </Typography>
                  <Typography variant="h5" component="div"  style={{ marginBottom: '16px'}}>                      
                  <strong>Contact Us</strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>Veritec Inc.</strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>2445 Winnetka Ave N</strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>Golden Valley, MN 55427</strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>United States</strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>Phone: (612) 991-7928 </strong>
                  </Typography>
                  <Typography component="div">                      
                  <strong>infor@veritecinc.com</strong>
                  </Typography>
                  <Typography component="div">                      
                   For more information, visit our website: www.veritecinc.com
                  </Typography>
                  </Typography>
              </div>         
            </div>
          </Paper>
        </Container>
        <Footer/>
      </Layout>
    )
  }
}

export default function(props) {
  const dispatch = useDispatch()
  const userData = useSelector(state => state.app.userData)
  const classes = useStyles()

  return (
    <About
      {...props}
      menuIndex={0}
      dispatch={dispatch}
      userData={userData}
      classes={classes}
    />
  )
}
